import React from "react";
import { Seo } from "../../components";
import "../../styles/success.css";
import "typeface-nunito-sans";

const success = () => {
	return (
		<main id='bodysuccess' className='h-screen'>
			<Seo
				title='Successful Booking '
				description='Thanks for choosing TNT Window Tinting'
			/>
			<div id='cardsuccess'>
				<div id='checkmarkdivsuccess'>
					<i id='checkmark'>✓</i>
				</div>
				<h1 id='h1success'>Success</h1>
				<p id='psuccess'>
					We received your quote and will contact you shortly. Thank
					you for choosing us.
				</p>
			</div>
			<br />
			<br />
			<a
				href='/'
				id='backtohome'
				className=' w-fit h-fit mt-16 font-bold text-2xl px-12 py-4 rounded-lg ml-auto mr-auto '
			>
				Back To Home
			</a>
		</main>
	);
};

export default success;
